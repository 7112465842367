body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    text-align: center;
  }
  
  body {
    background-color: #282c34;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  #wrapper {
    margin: 0 5%;
  }

  .centerView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @-webkit-keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

.splitScreen {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.pane {
    width: 50%;
    display: block;
}

.pane:first-of-type {
  padding-right: 2%;
}

.pane:last-of-type {
  padding-left: 2%;
}

.formItem {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.formItem:last-of-type {
  margin-bottom: 0;
}

.formLabel {
  width: 25%;
  display: block;
  text-align: left;
}

.formInput {
  width: 75%;
  display: block;
}

.formInput>input {
  width: 75%;
}

#uploadButton {
  background-color: #61dafb;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
}

.contentView {
  width: 100%;
  height: calc(90vh);
  margin-top: calc(5vh);
  margin-bottom: calc(5vh);
}

#copyright {
  margin-left: 5px;
}

input[type=text], input[type=password] {
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #282c34;
  padding: 4px 12px;
  margin: 0 12px;
  border-radius: 8px;
}

#copyright>a {
  color: white;
}

textarea::-webkit-scrollbar {
  display: none;
}

textarea {
  background-color: transparent;
  color: white;
  border: 1px inset white;
  border-radius: 16px;
  width: 100%;
  min-height: 16vh;
}

table {
  width: 100%;
}

.nameBox {
  width: 2em;
}
